window.createVisibilityObserver = function createVisibilityObserver(targetClass, callback, threshold = 0.1) {
  if (typeof callback !== 'function') {
    console.error('The callback argument passed must be a function.');
    return;
  }

  if (typeof threshold !== 'number' || threshold < 0 || threshold > 1) {
    console.error('Threshold must be a number between 0 and 1. The default value is used: 0.1.');
    threshold = 0.1;
  }

  function onElementVisible(entry) {
    if (entry.isIntersecting) {
      callback(entry.target);
    }
  }

  const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: threshold
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach(onElementVisible);
  }, observerOptions);

  if(document.querySelector(`.${targetClass}`)) {
    document.querySelectorAll(`.${targetClass}`).forEach((el) => {
      observer.observe(el);
    });
  }
}

/* createVisibilityObserver('my-class', funcName, 0.05); */

window.lineTitlesFunc = function lineTitlesFunc(titlesArr) {
  let maxHeight = 0;

  titlesArr.forEach((title) => {
    const height = title.offsetHeight;
    if (height > maxHeight) {
      maxHeight = height;
    }
  });

  titlesArr.forEach((title) => {
    title.style.minHeight = maxHeight + 'px';
  });
};